<mat-card class="card mat-elevation-z8">
  <mat-card-header>
    <mat-card-title>Cadastrar Documento</mat-card-title>
    <mat-card-subtitle>Formulário para cadastro</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <form class="form">
      <!-- Divider -->
      <hr class="solid" />

      <!-- General -->
      <mat-card-subtitle>Dados Mestre:</mat-card-subtitle>
      <!-- Colunas -->
      <mat-grid-list cols="4" rowHeight="150px">
        <mat-grid-tile colspan="1">
          <div
            mat-card-avatar
            class="example-header-image"
            *ngIf="!document.picture"
          ></div>
          <div *ngIf="document.picture">
            <img
              [lazyLoad]="document.picture"
              defaultImage="https://s3.amazonaws.com/37assets/svn/765-default-avatar.png"
              alt=""
              width="200"
              height="200"
            />
          </div>
        </mat-grid-tile>

        <!-- Coluna 1 -->
        <mat-grid-tile colspan="2">
          <div class="file-drop-container">
            <ngx-file-drop
              class="file-drop"
              dropZoneLabel="Drop files here"
              (onFileDrop)="dropped($event)"
              accept=".png, .jpg, .jpeg, .pdf"
            >
              <ng-template
                ngx-file-drop-content-tmp
                let-openFileSelector="openFileSelector"
              >
                Arraste os arquivos para cá ou
                <button
                  type="button"
                  mat-flat-button
                  color="primary"
                  class="mat-elevation-z4"
                  id="browseFilesButton"
                  (click)="openFileSelector()"
                >
                  Buscar arquivos
                </button>
              </ng-template>
            </ngx-file-drop>
            <div class="upload-table-container">
              <table class="upload-table">
                <tbody class="upload-name-style">
                  <tr *ngFor="let item of documents; let i = index">
                    <td>
                      <strong>{{ item.relativePath }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </mat-grid-tile>
      </mat-grid-list>

      <mat-grid-list cols="4" rowHeight="150px">
        <mat-grid-tile colspan="2">
          <mat-form-field appearance="legacy">
            <mat-label>Nome</mat-label>
            <input
              [(ngModel)]="document.key"
              name="key"
              [formControl]="keyFormControl"
              matInput
            />
            <mat-icon matSuffix>add_business</mat-icon>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="2">
          <mat-form-field appearance="outline">
            <mat-label>Selecione uma Função</mat-label>
            <mat-select
              [(ngModel)]="document.responsibility"
              name="responsibility"
              [formControl]="responsibility"
              required
              multiple
            >
              <mat-option
                *ngFor="let responsibility of responsibilities"
                value="{{ responsibility.id }}"
                >{{ responsibility.name }}</mat-option
              >
            </mat-select>
            <mat-hint
              *ngIf="!responsibility.value"
              align="end"
              style="color: red"
              >Campo obrigatório</mat-hint
            >
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </form>
  </mat-card-content>

  <mat-card-actions align="end">
    <button
      *ngIf="!this.document.key"
      (click)="onSave()"
      [disabled]="!validateFields()"
      class="success-button mat-elevation-z4"
      mat-raised-button
      extended
      matTooltip="Salvar o Documento"
    >
      <mat-icon>check</mat-icon> SALVAR
    </button>
    <button
      *ngIf="this.document.key"
      (click)="update()"
      [disabled]="!validateFields()"
      class="success-button mat-elevation-z4"
      mat-raised-button
      extended
      matTooltip="Salvar o Documento"
    >
      <mat-icon>check</mat-icon> ALTERAR
    </button>
    <button
      routerLink="../"
      class="mat-elevation-z4"
      mat-raised-button
      color="warn"
      extended
      matTooltip="Cancelar as informações preenchidas e voltar a página"
    >
      <mat-icon>block</mat-icon> CANCELAR
    </button>
  </mat-card-actions>

  <mat-card-footer>
    <mat-progress-bar mode="indeterminate" *ngIf="isSaving"></mat-progress-bar>
  </mat-card-footer>
</mat-card>
