<div class="container mt-4">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Metas</li>
    </ol>
  </nav>

  <h1>Lista de Metas</h1>

  <div *ngIf="loading" class="alert alert-info">
    Carregando metas...
  </div>

  <app-goal-task-table *ngIf="!loading" [headers]="goalHeaders" [data]="goalData" [itemsPerPage]="5" (finish)="onFinish($event)" (edit)="onEdit($event)" (delete)="onDelete($event)"></app-goal-task-table>
</div>
