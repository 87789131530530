<mat-card class="card mat-elevation-z8">
  <!-- Header -->
  <mat-card-header>
    <mat-card-subtitle (click)="backClicked()" style="cursor: pointer"
      >Voltar</mat-card-subtitle
    >
    <mat-card-title
      >{{ routineId ? "Editar " + routine.name : "Cadastrar Rotina" }}
    </mat-card-title>
    <mat-card-subtitle>Formulário para cadastro</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <form class="form">
      <!-- Divider -->
      <hr class="solid" />

      <!-- General -->
      <mat-card-subtitle>Dados Mestre:</mat-card-subtitle>
      <!-- Nome, Função -->
      <mat-grid-list cols="2" rowHeight="100px">
        <!-- Nome -->
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="legacy">
            <mat-label>Nome</mat-label>
            <input
              [(ngModel)]="routine.name"
              name="name"
              [formControl]="nameFormControl"
              required
              matInput
            />
            <mat-icon matSuffix>add_business</mat-icon>
            <mat-hint
              *ngIf="!nameFormControl.value"
              align="end"
              style="color: red"
              >Campo obrigatório</mat-hint
            >
          </mat-form-field>
        </mat-grid-tile>

        <!-- Função -->
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="outline">
            <mat-label>Selecione a Função</mat-label>
            <mat-select
              [(ngModel)]="selectedResponsibilityId"
              name="returnedResponsibilities"
              [formControl]="responsibilityFormControl"
              required
              multiple
            >
              <mat-option
                *ngFor="let responsibility of responsibilities"
                [value]="responsibility.id"
                >{{ responsibility.name }}</mat-option
              >
            </mat-select>
            <mat-hint
              *ngIf="!responsibilityFormControl.value"
              align="end"
              style="color: red"
              >Campo obrigatório</mat-hint
            >
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </form>
  </mat-card-content>

  <!-- Actions -->
  <mat-card-actions align="end">
    <button
      *ngIf="!this.routineId"
      (click)="save()"
      [disabled]="!validateFields()"
      class="success-button mat-elevation-z4"
      mat-raised-button
      extended
      matTooltip="Salvar as informações preenchidas"
    >
      <mat-icon>check</mat-icon> SALVAR
    </button>
    <button
      *ngIf="this.routineId"
      (click)="update()"
      [disabled]="!validateFields()"
      class="success-button mat-elevation-z4"
      mat-raised-button
      extended
      matTooltip="Alterar as informações preenchidas"
    >
      <mat-icon>check</mat-icon> ALTERAR
    </button>
    <button
      *ngIf="routineId"
      routerLink="../../"
      class="mat-elevation-z4"
      mat-raised-button
      color="warn"
      extended
      matTooltip="Cancelar as informações preenchidas e voltar a página"
    >
      <mat-icon>block</mat-icon> CANCELAR
    </button>
    <button
      *ngIf="!routineId"
      routerLink="../"
      class="mat-elevation-z4"
      mat-raised-button
      color="warn"
      extended
      matTooltip="Cancelar as informações preenchidas e voltar a página"
    >
      <mat-icon>block</mat-icon> CANCELAR
    </button>
  </mat-card-actions>

  <!-- Footer -->
  <mat-card-footer>
    <mat-progress-bar mode="indeterminate" *ngIf="isSaving"></mat-progress-bar>
  </mat-card-footer>
</mat-card>
