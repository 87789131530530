<!--<mat-card class="mat-elevation-z8">-->
<!--  <mat-card-header> </mat-card-header>-->
<!--  <mat-card-content>-->
<!--    <form-->
<!--      name="form"-->
<!--      (ngSubmit)="f.form.valid && onSubmit()"-->
<!--      #f="ngForm"-->
<!--      novalidate-->
<!--      class="form">-->
<!--      <mat-grid-list cols="1" rowHeight="100px">-->
<!--        <mat-grid-tile colspan="1">-->
<!--          <h1>LOGIN</h1>-->
<!--        </mat-grid-tile>-->
<!--      </mat-grid-list>-->
<!--      <mat-grid-list cols="1" rowHeight="100px">-->
<!--        <mat-grid-tile colspan="1">-->
<!--          <mat-form-field appearance="outline">-->
<!--            <mat-label>Usuário</mat-label>-->
<!--            <input-->
<!--              matInput-->
<!--              [formControl]="username"-->
<!--              [(ngModel)]="form.username"-->
<!--              name="username"-->
<!--              required />-->
<!--            <mat-icon matSuffix>login</mat-icon>-->
<!--            <mat-hint *ngIf="form.username === null || form.username === ''">-->
<!--              Digite o seu usuário-->
<!--            </mat-hint>-->
<!--          </mat-form-field>-->
<!--        </mat-grid-tile>-->
<!--      </mat-grid-list>-->
<!--      <mat-grid-list cols="1" rowHeight="100px">-->
<!--        <mat-form-field appearance="outline">-->
<!--          <mat-label>Senha</mat-label>-->
<!--          <input-->
<!--            matInput-->
<!--            [formControl]="password"-->
<!--            [(ngModel)]="form.password"-->
<!--            [type]="hide ? 'password' : 'text'"-->
<!--            name="password"-->
<!--            required />-->
<!--          <mat-icon matSuffix (click)="hide = !hide">{{ hide ? "visibility_off" : "visibility" }}</mat-icon>-->
<!--          <mat-hint *ngIf="form.password === null || form.password === ''">-->
<!--            Digite a sua senha-->
<!--          </mat-hint>-->
<!--        </mat-form-field>-->
<!--      </mat-grid-list>-->
<!--      <mat-grid-list cols="1" rowHeight="100px">-->
<!--        <button-->
<!--          mat-raised-button-->
<!--          color="primary"-->
<!--          *ngIf="!isLoggin"-->
<!--          (click)="onSubmit()"-->
<!--          [disabled]="!validateFields()">-->
<!--          Login-->
<!--        </button>-->
<!--      </mat-grid-list>-->
<!--    </form>-->
<!--  </mat-card-content>-->
<!--  <mat-card-footer>-->
<!--    <mat-spinner *ngIf="isLoggin"></mat-spinner>-->
<!--  </mat-card-footer>-->
<!--</mat-card>-->


<div class="login-container">
  <div class="login-card">
    <div class="login-column">
      <div style="text-align: left; font-weight: 700;">
        <span style="color: var(--primary);">Projeção</span>Profissional
      </div>
      <div style="padding-top: 2.5rem; padding-bottom: .5rem;">
        <h2>Acesse sua conta</h2>
        <div style="border-color: var(--primary); border-width: 2px; width: 2.5rem; display: inline-block; margin-bottom: 2.5rem;"></div>
        <form (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" [formGroup]="credentials">
          <div class="input-group">
            <input type="text" id="username" formControlName="username" placeholder="Digite seu usuário" required />
          </div>
          <div class="input-group" style="margin-bottom: 2.5rem;">
            <input type="password" id="password" formControlName="password" placeholder="Digite sua senha" required />
          </div>
          <a *ngIf="!isLoggin" type="submit" (click)="onSubmit()">Entrar</a>
        </form>

        <div *ngIf="isLoggin" class="spinner-container">
          <div class="spinner"></div>
        </div>
      </div>
    </div>

    <div class="signup-column">
      <h2 style="color: var(--white); font-weight: 600;">Seja Bem-Vindo</h2>
      <div style="border-color: var(--white); border-width: 2px; width: 2.5rem; display: inline-block; margin-bottom: .5rem"></div>
      <p>Não tem uma conta? Cadastre-se agora para ter acesso completo.</p>
      <a type="submit" class="sign-up">Cadastrar</a>
    </div>
  </div>
</div>
