<mat-card class="card mat-elevation-z8">
  <mat-card-header>
    <mat-card-title>Funções</mat-card-title>
    <mat-card-subtitle>Listagem</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <!-- Divider -->
    <mat-divider></mat-divider>

    <!-- Colunas -->
    <mat-grid-list cols="4" rowHeight="100px">
      <!-- Coluna 1 -->
      <mat-grid-tile colspan="2">
        <mat-form-field appearance="standard">
          <mat-label>Consulte a Função</mat-label>
          <input matInput (keyup)="applyFilter($event)" #input />
        </mat-form-field>
      </mat-grid-tile>

      <!-- Coluna 2 -->
      <mat-grid-tile colspan="1"></mat-grid-tile>

      <!-- Coluna 3 -->
      <mat-grid-tile colspan="1">
        <button routerLink="create" mat-raised-button color="primary">
          Cadastrar Função
        </button>
      </mat-grid-tile>
    </mat-grid-list>

    <!-- Divider -->
    <mat-divider></mat-divider>

    <!-- Tabela -->
    <div class="container">
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
            <td
              mat-cell
              *matCellDef="let element"
              (click)="editResponsibility(element.id)"
            >
              {{ element.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="fantasyName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Empresa</th>
            <td
              mat-cell
              *matCellDef="let element"
              (click)="editResponsibility(element.id)"
            >
              {{ element?.company?.fantasyName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Ações</th>
            <td mat-cell *matCellDef="let element">
              <button
                mat-icon-button
                [matMenuTriggerFor]="menu"
                aria-label="Example icon-button with a menu"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item routerLink="edit/{{ element.id }}">
                  <mat-icon>edit</mat-icon>
                  <span>Editar</span>
                </button>
                <button
                  mat-menu-item
                  (click)="
                    openDeleteConfirmationModal(element.id);
                    $event.stopPropagation()
                  "
                >
                  <mat-icon>delete</mat-icon>
                  <span>Deletar</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">
              Não existem dados cadastrados até o momento!
            </td>
          </tr>
        </table>

        <mat-paginator
          [pageSizeOptions]="[5, 10, 20, 50, 100]"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </div>
  </mat-card-content>

  <mat-card-footer>
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
  </mat-card-footer>
</mat-card>
