<div>
  <p>{{ message }}</p>
  <button
    class="mat-elevation-z8 confirm"
    mat-stroked-button
    (click)="confirmDelete()"
  >
    Sim
  </button>
  <button
    class="mat-elevation-z8 cancel"
    mat-stroked-button
    (click)="cancelDelete()"
  >
    Não
  </button>
</div>
