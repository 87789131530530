<mat-card class="card mat-elevation-z8">
  <!-- Header -->
  <mat-card-header>
    <mat-card-title>
      {{
        person.id
          ? "Alterar Cadastro: " + person.name
          : "Você está acessando com usuário ADMIN."
      }}
    </mat-card-title>
    <mat-card-subtitle>Formulário para alteração</mat-card-subtitle>
  </mat-card-header>

  <!-- Content -->
  <mat-card-content>
    <form class="form">
      <!-- Divider -->
      <hr class="solid" />

      <!-- General -->
      <mat-card-subtitle>Dados Mestre:</mat-card-subtitle>

      <!-- Name, CPF, ContractType, Gender -->
      <mat-grid-list cols="5" rowHeight="100px">
        <!-- Name -->
        <mat-grid-tile colspan="2">
          <mat-form-field class="fullWidth" appearance="legacy">
            <mat-label>Nome</mat-label>
            <input
              [(ngModel)]="person.name"
              name="name"
              [formControl]="name"
              required
              matInput
              maxlength="100"
            />
            <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
            <mat-hint *ngIf="!name.value" align="end" style="color: red"
              >Campo obrigatório</mat-hint
            >
          </mat-form-field>
        </mat-grid-tile>

        <!-- CPF -->
        <mat-grid-tile colspan="1">
          <mat-form-field class="fullWidth cpf-field" appearance="legacy">
            <mat-label>{{ "CPF" }}</mat-label>
            <input
              [(ngModel)]="person.cpfCnpj"
              name="cpf"
              [formControl]="cpf"
              required
              matInput
              maxlength="isCpf ? 14 : 18"
              [placeholder]="isCpf ? '000.000.000-00' : '00.000.000/0000-00'"
              [mask]="isCpf ? '000.000.000-00' : '00.000.000/0000-00'"
            />
            <mat-hint *ngIf="!cpf.value" align="end" style="color: red"
              >Campo obrigatório</mat-hint
            >
          </mat-form-field>
        </mat-grid-tile>

        <!-- ContractType -->
        <mat-grid-tile colspan="1">
          <mat-radio-group
            [(ngModel)]="radioContractTypeOptions"
            name="radioButtons"
            disabled="true"
          >
            <mat-radio-button (click)="selectContractType('Clt')" value="Clt"
              >CLT</mat-radio-button
            >
            <mat-radio-button
              (click)="selectContractType('Autônomo')"
              value="Autônomo"
              >Profissional</mat-radio-button
            >
          </mat-radio-group>
        </mat-grid-tile>

        <!-- Gender -->
        <mat-grid-tile colspan="1">
          <mat-radio-group
            [(ngModel)]="radioGenderOptions"
            name="radioGenderButtons"
          >
            <mat-radio-button
              (click)="selectGender('Masculino')"
              value="Masculino"
              >Masculino</mat-radio-button
            >
            <mat-radio-button
              (click)="selectGender('Feminino')"
              value="Feminino"
              >Feminino</mat-radio-button
            >
          </mat-radio-group>
        </mat-grid-tile>
      </mat-grid-list>

      <!-- Username, Email -->
      <mat-grid-list cols="3" rowHeight="100px">
        <!-- Username -->
        <mat-grid-tile colspan="1">
          <mat-form-field class="fullWidth" appearance="legacy">
            <mat-label>Nome de Usuário</mat-label>
            <input
              [(ngModel)]="person.user.username"
              name="username"
              [formControl]="username"
              required
              matInput
              placeholder="Ex.. fulano44"
            />
            <mat-icon matSuffix>person</mat-icon>
            <mat-hint *ngIf="!username.value" align="end" style="color: red"
              >Campo obrigatório</mat-hint
            >
          </mat-form-field>
        </mat-grid-tile>

        <!-- Email -->
        <mat-grid-tile colspan="1">
          <mat-form-field class="fullWidth" appearance="legacy">
            <mat-label>E-mail</mat-label>
            <input
              [(ngModel)]="person.user.email"
              name="email"
              [formControl]="email"
              required
              matInput
              placeholder="Ex.. fulano@mail.com"
            />
            <mat-icon matSuffix>email</mat-icon>
            <mat-hint *ngIf="!email.value" align="end" style="color: red"
              >Campo obrigatório</mat-hint
            >
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>

      <!-- Password, NewPassword, ConfirmPassword -->
      <mat-grid-list cols="3" rowHeight="100px">
        <!-- Password -->
        <mat-grid-tile colspan="1">
          <mat-form-field class="fullWidth" appearance="legacy">
            <mat-label>Senha antiga:</mat-label>
            <input
              name="password"
              [formControl]="password"
              [type]="hidePassword ? 'password' : 'text'"
              matInput
            />
            <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{
              hidePassword ? "visibility_off" : "visibility"
            }}</mat-icon>
          </mat-form-field>
        </mat-grid-tile>

        <!-- NewPassword -->
        <mat-grid-tile colspan="1">
          <mat-form-field class="fullWidth" appearance="legacy">
            <mat-label>Nova Senha:</mat-label>
            <input
              [(ngModel)]="person.user.password"
              name="newPassword"
              [formControl]="newPassword"
              [type]="hideNewPassword ? 'password' : 'text'"
              matInput
            />
            <mat-icon matSuffix (click)="hideNewPassword = !hideNewPassword">{{
              hideNewPassword ? "visibility_off" : "visibility"
            }}</mat-icon>
          </mat-form-field>
        </mat-grid-tile>

        <!-- ConfirmPassword -->
        <mat-grid-tile colspan="1">
          <mat-form-field class="fullWidth" appearance="legacy">
            <mat-label>Digite a Nova Senha novamente:</mat-label>
            <input
              [(ngModel)]="confirmPassword"
              name="confirmPassword"
              [formControl]="confirmPasswordFormControl"
              [type]="hideConfirmPassword ? 'password' : 'text'"
              matInput
            />
            <mat-icon
              matSuffix
              (click)="hideConfirmPassword = !hideConfirmPassword"
              >{{
                hideConfirmPassword ? "visibility_off" : "visibility"
              }}</mat-icon
            >
            <mat-hint *ngIf="!newPassword.value" align="end" style="color: red"
              >Campo obrigatório</mat-hint
            >
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </form>
  </mat-card-content>

  <!-- Actions -->
  <mat-card-actions align="end">
    <button
      (click)="update()"
      [disabled]="!validateFields()"
      class="success-button mat-elevation-z4"
      mat-raised-button
      extended
      matTooltip="Salvar as alterações no usuário"
    >
      <mat-icon>check</mat-icon> SALVAR
    </button>
  </mat-card-actions>

  <!-- Footer -->
  <mat-card-footer>
    <mat-progress-bar mode="indeterminate" *ngIf="isSaving"></mat-progress-bar>
  </mat-card-footer>
</mat-card>
