<h2 mat-dialog-title>Cadastrar Meta:</h2>
<mat-dialog-content class="mat-typography">
  <p>
    <mat-form-field appearance="outline">
      <mat-label>Descrição:</mat-label>
      <textarea
        matInput
        placeholder="Digite a descrição da meta"
        rows="5"
        [(ngModel)]="achieve.description"
        [formControl]="descriptionFormControl"
      ></textarea>
      <mat-hint>Digite a descrição da meta.</mat-hint>
    </mat-form-field>
  </p>

  <mat-form-field appearance="fill">
    <mat-label>Escolha a data:</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
      <input
        matStartDate
        formControlName="start"
        placeholder="Data de início"
        [(ngModel)]="achieve.startDate"
      />
      <input
        matEndDate
        formControlName="end"
        placeholder="Data de fim"
        [(ngModel)]="achieve.endDate"
      />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>

    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')"
      >Invalid start date</mat-error
    >
    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')"
      >Invalid end date</mat-error
    >
  </mat-form-field>
</mat-dialog-content>

<mat-divider></mat-divider>
<mat-dialog-actions align="center">
  <button
    class="cancel-button"
    mat-raised-button
    mat-dialog-close
    matTooltip="Fechar a tela de edição"
  >
    Cancelar
  </button>
  <button
    class="success-button"
    mat-raised-button
    [mat-dialog-close]="true"
    cdkFocusInitial
    (click)="save()"
    [disabled]="!validateFields()"
    matTooltip="Salvar as informações preenchidas"
  >
    <div>Salvar</div>
  </button>
</mat-dialog-actions>
