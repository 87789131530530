<table mat-table [dataSource]="dataSource">
  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <p>
        {{
          element.name.length > this.maxCharacters
            ? element.name.substring(0, this.maxCharacters) + "..."
            : element.name
        }}
      </p>
    </td>
  </ng-container>

  <ng-container matColumnDef="radio">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <mat-radio-group>
        <mat-radio-button
          name="daily"
          *ngFor="let tag of tags"
          value="{{ tag.id }}"
          class="daily"
          [ngClass]="tag.class"
          [checked]="tag.id == element.tag?.id"
          (click)="openConfirm(tag.id, element)"
          matTooltip="{{ tag.description }}"
        ></mat-radio-button>
      </mat-radio-group>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
