<mat-card class="card mat-elevation-z8">
  <mat-card-header>
    <mat-card-title
      ><mat-icon>groups</mat-icon> Profissionais Autônomos</mat-card-title
    >
    <mat-card-subtitle>Listagem</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <!-- Divider -->
    <mat-divider></mat-divider>

    <!-- Colunas -->
    <mat-grid-list cols="2" rowHeight="100px">
      <!-- Coluna 1 -->
      <mat-grid-tile colspan="1">
        <mat-form-field appearance="standard">
          <mat-label
            ><mat-icon>person_search</mat-icon> Consulte o
            Profissional</mat-label
          >
          <input matInput (keyup)="applyFilter($event)" #input />
        </mat-form-field>
      </mat-grid-tile>

      <!-- Coluna 2 -->
      <mat-grid-tile colspan="1">
        <button routerLink="create" mat-raised-button color="primary">
          <mat-icon>person_add</mat-icon> Cadastrar Profissional
        </button>
      </mat-grid-tile>
    </mat-grid-list>

    <!-- Divider -->
    <mat-divider></mat-divider>

    <!-- TIRAR O COMENTÁRIO QUANDO FOR ADICIONAR OS FILTROS -->
    <!-- <mat-grid-list cols="1" rowHeight="80px">
      <mat-grid-tile colspan="1">
        <section>
          <div class="example-button-row">
            <button mat-stroked-button>
              <mat-icon fontIcon="filter_list"></mat-icon>
              Basic
            </button>
            <button mat-stroked-button>
              <mat-icon fontIcon="filter_list"></mat-icon>
              Basic
            </button>
            <button mat-stroked-button>
              <mat-icon fontIcon="filter_list"></mat-icon>
              Basic
            </button>
            <button mat-stroked-button>
              <mat-icon fontIcon="filter_list"></mat-icon>
              Basic
            </button>
          </div>
        </section>
      </mat-grid-tile>
    </mat-grid-list> -->

    <!-- Tabela -->
    <div class="container">
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="picture">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td
              mat-cell
              *matCellDef="let element"
              (click)="openAppointment(element.id)"
            >
              <div
                mat-card-avatar
                class="example-header-image"
                *ngIf="!element.picture"
              ></div>
              <div *ngIf="element.picture">
                <img
                  [lazyLoad]="element.picture"
                  defaultImage="https://s3.amazonaws.com/37assets/svn/765-default-avatar.png"
                  alt=""
                  width="70"
                  height="70"
                />
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
            <td
              mat-cell
              *matCellDef="let element"
              (click)="openAppointment(element.id)"
            >
              {{ element.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="fantasyName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Unidade</th>
            <td mat-cell *matCellDef="let element" (click)="edit(element.id)">
              {{ element?.office?.fantasyName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="responsibility">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Função</th>
            <td mat-cell *matCellDef="let element" (click)="edit(element.id)">
              {{ element.responsibility.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="routines">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Rotinas</th>
            <td mat-cell *matCellDef="let element">
              <button
                mat-icon-button
                (click)="getRoutinesByPerson(element.id)"
                aria-label="Example icon-button with a menu"
                matTooltip="Visualize todas as Rotinas para esse Colaborador"
              >
                <mat-icon>visibility</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="goals">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Metas</th>
            <td mat-cell *matCellDef="let element">
              <button
                mat-icon-button
                (click)="getGoalsByPerson(element.id)"
                aria-label="Example icon-button with a menu"
                matTooltip="Visualize todas as Metas para esse Colaborador"
              >
                <mat-icon>visibility</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Ações</th>
            <td mat-cell *matCellDef="let element">
              <button
                *ngIf="
                  this.isAdmin ||
                  this.isAdminGeral ||
                  this.isAdminEmpresa ||
                  this.isAdminOffice
                "
                mat-icon-button
                [matMenuTriggerFor]="menu"
                aria-label="Example icon-button with a menu"
                matTooltip="Editar ou Desativar o Colaborador"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item routerLink="edit/{{ element.id }}">
                  <mat-icon>edit</mat-icon>
                  <span>Editar</span>
                </button>
                <button
                  mat-menu-item
                  (click)="
                    openDeleteConfirmationModal(element.id, element.name);
                    $event.stopPropagation()
                  "
                >
                  <mat-icon>delete</mat-icon>
                  <span>Desativar</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="table-row"
          ></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">
              Não existem dados cadastrados até o momento!
            </td>
          </tr>
        </table>

        <mat-paginator
          [pageSizeOptions]="[5, 10, 20, 50, 100]"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </div>
  </mat-card-content>

  <mat-card-footer>
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
  </mat-card-footer>
</mat-card>
