<mat-card class="card mat-elevation-z8">
  <mat-card-header>
    <mat-card-subtitle (click)="backClicked()" style="cursor: pointer"
      >Voltar</mat-card-subtitle
    >
    <mat-card-title
      >{{ segmentId ? "Editar " + segment.name : "Cadastrar Segmento" }}
    </mat-card-title>
    <mat-card-subtitle>Formulário para cadastro</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <form class="form">
      <!-- Divider -->
      <hr class="solid" />

      <!-- General -->
      <mat-card-subtitle>Dados Mestre:</mat-card-subtitle>
      <!-- Colunas -->
      <mat-grid-list cols="2" rowHeight="100px">
        <!-- Coluna 1 -->
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="legacy">
            <mat-label>Nome</mat-label>
            <input
              [(ngModel)]="segment.name"
              name="name"
              [formControl]="name"
              required
              matInput
            />
            <mat-icon matSuffix>add_business</mat-icon>
            <mat-hint *ngIf="!name.value" align="end" style="color: red"
              >Campo obrigatório</mat-hint
            >
          </mat-form-field>
        </mat-grid-tile>

        <!-- Coluna 2 -->
        <mat-grid-tile colspan="1"> </mat-grid-tile>
      </mat-grid-list>
    </form>
  </mat-card-content>

  <mat-card-actions align="end">
    <button
      (click)="openSegmentForm()"
      [disabled]="!validateFields()"
      class="success-button mat-elevation-z4"
      mat-raised-button
      extended
      matTooltip="Salvar as informações preenchidas"
    >
      <mat-icon>check</mat-icon> SALVAR
    </button>
    <button
      *ngIf="segmentId"
      routerLink="../../"
      class="mat-elevation-z4"
      mat-raised-button
      color="warn"
      extended
      matTooltip="Cancelar as informações preenchidas e voltar a página"
    >
      <mat-icon>block</mat-icon> CANCELAR
    </button>
    <button
      *ngIf="!segmentId"
      routerLink="../"
      class="mat-elevation-z4"
      mat-raised-button
      color="warn"
      extended
      matTooltip="Cancelar as informações preenchidas e voltar a página"
    >
      <mat-icon>block</mat-icon> CANCELAR
    </button>
  </mat-card-actions>

  <mat-card-footer>
    <mat-progress-bar mode="indeterminate" *ngIf="isSaving"></mat-progress-bar>
  </mat-card-footer>
</mat-card>
