<mat-card class="card mat-elevation-z8">
  <!-- Header -->
  <mat-card-header>
    <mat-card-title>
      Alterar Senha
    </mat-card-title>
    <mat-card-subtitle>Formulário para alteração</mat-card-subtitle>
    <mat-card-subtitle>
      {{
        person.id
          ? "Usuário: " + person.name
          : "Você está acessando com usuário ADMIN."
      }}
    </mat-card-subtitle>
  </mat-card-header>

  <!-- Content -->
  <mat-card-content>
    <form class="form">
      <!-- Divider -->
      <hr class="solid" />

      <!-- General -->
      <mat-card-subtitle>Dados Mestre:</mat-card-subtitle>

      <!-- Password, NewPassword, ConfirmPassword -->
      <mat-grid-list cols="3" rowHeight="100px" [formGroup]="passwordForm">
        <!-- Password -->
        <mat-grid-tile colspan="1">
          <mat-form-field class="fullWidth" appearance="legacy">
            <mat-label>Senha antiga:</mat-label>
            <input
              name="password"
              formControlName="currentPassword"
              [type]="hidePassword ? 'password' : 'text'"
              matInput
            />
            <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{
                hidePassword ? "visibility_off" : "visibility"
              }}</mat-icon>
            <mat-hint *ngIf="passwordForm.get('currentPassword')?.value == null" align="end" style="color: red">
              Campo obrigatório
            </mat-hint>
          </mat-form-field>
        </mat-grid-tile>

        <!-- NewPassword -->
        <mat-grid-tile colspan="1">
          <mat-form-field class="fullWidth" appearance="legacy">
            <mat-label>Nova Senha:</mat-label>
            <input
              name="newPassword"
              formControlName="newPassword"
              [type]="hideNewPassword ? 'password' : 'text'"
              matInput
            />
            <mat-icon matSuffix (click)="hideNewPassword = !hideNewPassword">{{
                hideNewPassword ? "visibility_off" : "visibility"
              }}</mat-icon>
            <mat-hint *ngIf="passwordForm.get('newPassword')?.value == null" align="end" style="color: red">
              Campo obrigatório
            </mat-hint>
          </mat-form-field>
        </mat-grid-tile>

        <!-- ConfirmPassword -->
        <mat-grid-tile colspan="1">
          <mat-form-field class="fullWidth" appearance="legacy">
            <mat-label>Digite a Nova Senha novamente:</mat-label>
            <input
              [(ngModel)]="confirmPassword"
              name="confirmPassword"
              formControlName="confirmPassword"
              [type]="hideConfirmPassword ? 'password' : 'text'"
              matInput
            />
            <mat-icon
              matSuffix
              (click)="hideConfirmPassword = !hideConfirmPassword"
            >{{
                hideConfirmPassword ? "visibility_off" : "visibility"
              }}</mat-icon
            >
            <mat-hint *ngIf="passwordForm.get('confirmPassword')?.value == null" align="end" style="color: red">
              Campo obrigatório
            </mat-hint>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </form>
  </mat-card-content>

  <!-- Actions -->
  <mat-card-actions align="end">
    <button
      (click)="update()"
      [disabled]="!validateFields()"
      class="success-button mat-elevation-z4"
      mat-raised-button
      extended
      matTooltip="Salvar as alterações no usuário"
    >
      <mat-icon>check</mat-icon> ALTERAR
    </button>
  </mat-card-actions>

  <!-- Footer -->
  <mat-card-footer>
    <mat-progress-bar mode="indeterminate" *ngIf="isSaving"></mat-progress-bar>
  </mat-card-footer>
</mat-card>
