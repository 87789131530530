<app-table
  title="Fornecedores"
  subtitle="Listagem"
  iconCardTitle="groups"
  iconSearch="person_search"
  iconAdd="person_add"
  [isLoading]="isLoading"
  [persons]="this.persons"
  [displayedColumns]="displayedColumns"
  (openAppointmentEvent)="openAppointment($event)"
  (editEvent)="edit($event)"
  (getRoutinesByPersonEvent)="getRoutinesByPerson($event)"
  (getGoalsByPersonEvent)="getGoalsByPerson($event)"
  (openDeleteConfirmationModalEvent)="openDeleteConfirmationModal($event)"
></app-table>
