<mat-card class="card mat-elevation-z8">
  <!-- Header -->
  <mat-card-header>
    <mat-card-title>Relatórios</mat-card-title>
    <mat-card-subtitle>Relatórios de acompanhamento</mat-card-subtitle>
  </mat-card-header>

  <!-- Content -->
  <mat-card-content>
    <ngx-charts-bar-vertical
      [view]="[1000, 400]"
      [results]="dataset"
      [xAxisLabel]="'x-axis'"
      [legendTitle]="'legend'"
      [yAxisLabel]="'y-axis'"
      [legend]="true"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      [xAxis]="true"
      [yAxis]="true"
      [gradient]="true"
    >
    </ngx-charts-bar-vertical>
  </mat-card-content>
</mat-card>
