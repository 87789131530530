<app-forms
  [id]="this.personId"
  title="Fornecedor"
  [isSaving]="this.isLoading"
  [person]="this.person"
  radioContractTypeOptions="Fornecedor"
  [isAdmin]="this.isAdmin"
  [isCpf]="this.isCpf"
  [officies]="this.officies"
  [addressSearch]="this.addressSearch"
  [responsibilities]="this.responsibilities"
  (saveEvent)="save($event)"
  (updateEvent)="update($event)"
  (addressEvent)="getAddress($event)"
></app-forms>
