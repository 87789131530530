<mat-card class="card mat-elevation-z8">
  <!-- Header -->
  <mat-card-header>
    <mat-card-subtitle (click)="backClicked()" style="cursor: pointer"
      >Voltar</mat-card-subtitle
    >
    <mat-card-title
      >{{ officeId ? "Editar " + office.fantasyName : "Cadastrar Unidade" }}
    </mat-card-title>
    <mat-card-subtitle>Formulário para cadastro</mat-card-subtitle>
  </mat-card-header>

  <!-- Content -->
  <mat-card-content>
    <form class="form">
      <!-- Divider -->
      <hr class="solid" />

      <!-- General -->
      <mat-card-subtitle>Dados Mestre:</mat-card-subtitle>
      <!-- Colunas -->
      <mat-grid-list cols="2" rowHeight="100px">
        <!-- Coluna 1 -->
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="legacy">
            <mat-label>Razão Social</mat-label>
            <input
              [(ngModel)]="office.corporateReason"
              name="corporateReason"
              [formControl]="corporateReason"
              required
              matInput
            />
            <mat-icon matSuffix>add_business</mat-icon>
            <mat-hint
              *ngIf="!corporateReason.value"
              align="end"
              style="color: red"
              >Campo obrigatório</mat-hint
            >
          </mat-form-field>
        </mat-grid-tile>

        <!-- Coluna 2 -->
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="legacy">
            <mat-label>Nome Fantasia</mat-label>
            <input
              [(ngModel)]="office.fantasyName"
              name="fantasyName"
              [formControl]="fantasyName"
              required
              matInput
            />
            <mat-icon matSuffix>domain</mat-icon>
            <mat-hint *ngIf="!fantasyName.value" align="end" style="color: red"
              >Campo obrigatório</mat-hint
            >
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
      <!-- Colunas -->
      <mat-grid-list cols="4" rowHeight="100px">
        <!-- Coluna 1 -->
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="legacy">
            <mat-label>CNPJ</mat-label>
            <input
              [(ngModel)]="office.cnpj"
              name="cnpj"
              [formControl]="cnpj"
              required
              matInput
              [mask]="'00.000.000/0000-00'"
              maxlength="18"
              placeholder="00.000.000/0000-00"
            />
            <mat-icon matSuffix>cases</mat-icon>
            <mat-hint *ngIf="!cnpj.value" align="end" style="color: red"
              >Campo obrigatório</mat-hint
            >
          </mat-form-field>
        </mat-grid-tile>

        <!-- Coluna 2 -->
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="legacy">
            <mat-label>Identificador de acesso</mat-label>
            <input
              [(ngModel)]="office.identifier"
              name="identifier"
              [formControl]="identifier"
              required
              matInput
              placeholder="geral"
            />
            <mat-icon matSuffix>badge</mat-icon>
            <mat-hint *ngIf="!identifier.value" align="end" style="color: red"
              >Campo obrigatório</mat-hint
            >
          </mat-form-field>
        </mat-grid-tile>

        <!-- Coluna 3 -->
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="legacy">
            <mat-label>E-mail</mat-label>
            <input
              [(ngModel)]="office.email"
              name="email"
              [formControl]="email"
              matInput
            />
            <mat-icon matSuffix>alternate_email</mat-icon>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Coluna 4 -->
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="legacy">
            <mat-label>Website</mat-label>
            <input
              [(ngModel)]="office.website"
              name="website"
              [formControl]="website"
              matInput
            />
            <mat-icon matSuffix>language</mat-icon>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
      <!-- Colunas -->
      <mat-grid-list cols="4" rowHeight="100px">
        <!-- Coluna 1 -->
        <mat-grid-tile colspan="2">
          <mat-form-field appearance="outline">
            <mat-label>Selecione uma Empresa</mat-label>
            <mat-select
              [(ngModel)]="office.company"
              (ngModelChange)="selectCompany()"
              name="company"
              [formControl]="company"
              required
            >
              <mat-option *ngFor="let company of companies" [value]="company">{{
                company.fantasyName
              }}</mat-option>
            </mat-select>
            <mat-hint *ngIf="!company.value" align="end" style="color: red"
              >Campo obrigatório</mat-hint
            >
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>

      <!-- Divider -->
      <hr class="solid" />

      <!-- Contact -->
      <mat-card-subtitle>Contatos:</mat-card-subtitle>
      <!-- Colunas -->
      <mat-grid-list cols="4" rowHeight="100px">
        <!-- Coluna 1 -->
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="legacy">
            <mat-label>Telefone</mat-label>
            <input
              [(ngModel)]="office.contact.phone"
              name="phone"
              [formControl]="phone"
              matInput
            />
            <mat-icon matSuffix>add_business</mat-icon>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Coluna 2 -->
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="legacy">
            <mat-label>Celular</mat-label>
            <input
              [(ngModel)]="office.contact.cellphone"
              name="cellphone"
              [formControl]="cellphone"
              matInput
            />
            <mat-icon matSuffix>domain</mat-icon>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>

      <!-- Divider -->
      <hr class="solid" />

      <!-- Address -->
      <mat-card-subtitle>Endereço:</mat-card-subtitle>
      <!-- Colunas -->
      <mat-grid-list cols="4" rowHeight="100px">
        <!-- Coluna 1 -->
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="legacy">
            <mat-label>CEP</mat-label>
            <input
              [(ngModel)]="office.address.cep"
              name="cep"
              [formControl]="cep"
              type="text"
              matInput
              placeholder="Ex.. 12444-666"
              mask="00000-000"
            />
            <mat-icon matSuffix>location_on</mat-icon>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Coluna 2 -->
        <mat-grid-tile colspan="1">
          <mat-form-field class="fullWidth" appearance="legacy">
            <mat-label>Estado</mat-label>
            <input
              [(ngModel)]="office.address.uf"
              name="uf"
              [formControl]="uf"
              type="uf"
              matInput
            />
            <mat-icon matSuffix>satellite</mat-icon>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Coluna 3 -->
        <mat-grid-tile colspan="1">
          <mat-form-field class="fullWidth" appearance="legacy">
            <mat-label>Cidade</mat-label>
            <input
              [(ngModel)]="office.address.city"
              name="city"
              [formControl]="city"
              type="city"
              matInput
            />
            <mat-icon matSuffix>location_city</mat-icon>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
      <!-- Colunas -->
      <mat-grid-list cols="4" rowHeight="100px">
        <!-- Coluna 1 -->
        <mat-grid-tile colspan="1">
          <mat-form-field class="fullWidth" appearance="legacy">
            <mat-label>Rua</mat-label>
            <input
              [(ngModel)]="office.address.streetName"
              name="streetName"
              [formControl]="streetName"
              type="streetName"
              matInput
            />
            <mat-icon matSuffix>add_road</mat-icon>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Coluna 2 -->
        <mat-grid-tile colspan="1">
          <mat-form-field class="fullWidth" appearance="legacy">
            <mat-label>Bairro</mat-label>
            <input
              [(ngModel)]="office.address.neighborhood"
              name="neighborhood"
              [formControl]="neighborhood"
              type="neighborhood"
              matInput
            />
            <mat-icon matSuffix>signpost</mat-icon>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Coluna 3 -->
        <mat-grid-tile colspan="1">
          <mat-form-field class="fullWidth" appearance="legacy">
            <mat-label>Complemento</mat-label>
            <input
              [(ngModel)]="office.address.complement"
              name="complement"
              [formControl]="complement"
              type="complement"
              matInput
            />
            <mat-icon matSuffix>edit_location_alt</mat-icon>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </form>
  </mat-card-content>

  <!-- Actions -->
  <mat-card-actions align="end">
    <button
      (click)="openOfficeForm()"
      [disabled]="!validateFields()"
      class="success-button mat-elevation-z4"
      mat-raised-button
      extended
      matTooltip="Salvar as informações preenchidas"
    >
      <mat-icon>check</mat-icon> SALVAR
    </button>
    <button
      *ngIf="officeId"
      routerLink="../../"
      class="mat-elevation-z4"
      mat-raised-button
      color="warn"
      extended
      matTooltip="Cancelar as informações preenchidas e voltar a página"
    >
      <mat-icon>block</mat-icon> CANCELAR
    </button>
    <button
      *ngIf="!officeId"
      routerLink="../"
      class="mat-elevation-z4"
      mat-raised-button
      color="warn"
      extended
      matTooltip="Cancelar as informações preenchidas e voltar a página"
    >
      <mat-icon>block</mat-icon> CANCELAR
    </button>
  </mat-card-actions>

  <!-- Footer -->
  <mat-card-footer>
    <mat-progress-bar mode="indeterminate" *ngIf="isSaving"></mat-progress-bar>
  </mat-card-footer>
</mat-card>
