<mat-card class="card mat-elevation-z8">
  <!-- Header -->
  <mat-card-header>
    <mat-card-subtitle (click)="backClicked()" style="cursor: pointer"
      >Voltar</mat-card-subtitle
    >
    <mat-card-title>
      {{ id ? "Editar " + person.name : "Cadastrar " + title }}
    </mat-card-title>
    <mat-card-subtitle>Formulário para cadastro</mat-card-subtitle>
  </mat-card-header>

  <!-- Content -->
  <mat-card-content>
    <form class="form">
      <!-- Divider -->
      <hr class="solid" />

      <!-- General -->
      <mat-card-subtitle>Dados Mestre:</mat-card-subtitle>
      <!-- Name, CPF, ContractType, Gender -->
      <mat-grid-list cols="5" rowHeight="100px">
        <!-- Name -->
        <mat-grid-tile colspan="2">
          <mat-form-field class="fullWidth" appearance="legacy">
            <mat-label>Nome</mat-label>
            <input
              [(ngModel)]="person.name"
              name="name"
              [formControl]="nameFormControl"
              required
              matInput
              maxlength="100"
            />
            <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
            <mat-hint
              *ngIf="!nameFormControl.value"
              align="end"
              style="color: red"
              >Campo obrigatório</mat-hint
            >
          </mat-form-field>
        </mat-grid-tile>

        <!-- CPF -->
        <mat-grid-tile colspan="1">
          <mat-form-field class="fullWidth cpf-field" appearance="legacy">
            <mat-label>{{ "CNPJ" }}</mat-label>
            <input
              [(ngModel)]="person.cpfCnpj"
              name="cpf"
              [formControl]="cpfFormControl"
              required
              matInput
              maxlength="isCpf ? 14 : 18"
              [placeholder]="isCpf ? '000.000.000-00' : '00.000.000/0000-00'"
              [mask]="isCpf ? '000.000.000-00' : '00.000.000/0000-00'"
            />
            <mat-hint
              *ngIf="!cpfFormControl.value"
              align="end"
              style="color: red"
              >Campo obrigatório</mat-hint
            >
          </mat-form-field>
        </mat-grid-tile>

        <!-- ContractType -->
        <mat-grid-tile colspan="1">
          <mat-radio-group
            [(ngModel)]="radioContractTypeOptions"
            name="radioButtons"
            disabled="true"
          >
            <mat-radio-button value="Clt">CLT</mat-radio-button>
            <mat-radio-button value="Autônomo">Profissional</mat-radio-button>
            <mat-radio-button value="Fornecedor">Fornecedor</mat-radio-button>
          </mat-radio-group>
        </mat-grid-tile>

        <!-- Gender -->
        <mat-grid-tile colspan="1">
          <mat-radio-group
            [(ngModel)]="radioGenderOptions"
            name="radioGenderButtons"
          >
            <mat-radio-button (click)="getGender('Masculino')" value="Masculino"
              >Masculino</mat-radio-button
            >
            <mat-radio-button (click)="getGender('Feminino')" value="Feminino"
              >Feminino</mat-radio-button
            >
          </mat-radio-group>
        </mat-grid-tile>
      </mat-grid-list>

      <!-- Username, Email -->
      <mat-grid-list cols="3" rowHeight="100px">
        <!-- Username -->
        <mat-grid-tile colspan="1">
          <mat-form-field class="fullWidth" appearance="legacy">
            <mat-label>Nome de Usuário</mat-label>
            <input
              [(ngModel)]="person.user.username"
              name="username"
              [formControl]="usernameFormControl"
              required
              matInput
              placeholder="Ex.. fulano44"
            />
            <mat-icon matSuffix>person</mat-icon>
            <mat-hint
              *ngIf="!usernameFormControl.value"
              align="end"
              style="color: red"
              >Campo obrigatório</mat-hint
            >
          </mat-form-field>
        </mat-grid-tile>

        <!-- Email -->
        <mat-grid-tile colspan="1">
          <mat-form-field class="fullWidth" appearance="legacy">
            <mat-label>E-mail</mat-label>
            <input
              [(ngModel)]="person.user.email"
              name="email"
              [formControl]="emailFormControl"
              required
              matInput
              placeholder="Ex.. fulano@mail.com"
            />
            <mat-icon matSuffix>email</mat-icon>
            <mat-hint
              *ngIf="!emailFormControl.value"
              align="end"
              style="color: red"
              >Campo obrigatório</mat-hint
            >
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>

      <!-- isAdmin true = Unidade, Função -->
      <mat-grid-list cols="4" rowHeight="100px" *ngIf="this.isAdmin">
        <!-- Unidade -->
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="outline">
            <mat-label>Selecione a Unidade</mat-label>
            <mat-select
              [(ngModel)]="person.officeId"
              name="office"
              [formControl]="officeFormControl"
            >
              <mat-option *ngFor="let office of officies" [value]="office.id">{{
                office.fantasyName
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Permissão -->
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="outline">
            <mat-label>Selecione as Permissões</mat-label>
            <mat-select
              [(ngModel)]="person.user.roles"
              [compareWith]="compareRoles"
              name="role"
              [formControl]="roleFormControl"
              required
              multiple
            >
              <mat-option
                *ngFor="let role of roleLabels"
                [value]="role.value"
                >{{ role.label }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Função -->
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="outline">
            <mat-label>Selecione a Função</mat-label>
            <mat-select
              [(ngModel)]="person.responsibilityId"
              name="responsibility"
              [formControl]="responsibilityFormControl"
            >
              <mat-option
                *ngFor="let responsibility of responsibilities"
                [value]="responsibility.id"
                >{{ responsibility.name }}</mat-option
              >
            </mat-select>
            <mat-hint
              *ngIf="!responsibilityFormControl.value"
              align="end"
              style="color: red"
              >Campo obrigatório</mat-hint
            >
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>

      <!-- isAdmin false = Unidade, Função -->
      <mat-grid-list cols="2" rowHeight="100px" *ngIf="!this.isAdmin">
        <!-- Unidade -->
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="outline">
            <mat-label>Selecione a Unidade</mat-label>
            <mat-select
              [(ngModel)]="person.officeId"
              name="office"
              [formControl]="officeFormControl"
            >
              <mat-option *ngFor="let office of officies" [value]="office.id">{{
                office.fantasyName
              }}</mat-option>
            </mat-select>
            <mat-hint
              *ngIf="!officeFormControl.value"
              align="end"
              style="color: red"
              >Campo obrigatório</mat-hint
            >
          </mat-form-field>
        </mat-grid-tile>

        <!-- Função -->
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="outline">
            <mat-label>Selecione a Função</mat-label>
            <mat-select
              [(ngModel)]="person.responsibilityId"
              name="responsibility"
              [formControl]="responsibilityFormControl"
            >
              <mat-option
                *ngFor="let responsibility of responsibilities"
                [value]="responsibility.id"
                >{{ responsibility.name }}</mat-option
              >
            </mat-select>
            <mat-hint
              *ngIf="!responsibilityFormControl.value"
              align="end"
              style="color: red"
              >Campo obrigatório</mat-hint
            >
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>

      <!-- Picture -->
      <mat-grid-list cols="4" rowHeight="200px">
        <mat-grid-tile colspan="1">
          <div
            mat-card-avatar
            class="example-header-image"
            *ngIf="!person.picture"
          ></div>
          <div *ngIf="person.picture">
            <img
              [lazyLoad]="person.picture"
              defaultImage="https://s3.amazonaws.com/37assets/svn/765-default-avatar.png"
              alt=""
              width="200"
              height="200"
            />
          </div>
        </mat-grid-tile>
        <mat-grid-tile colspan="1">
          <div class="file-drop-container">
            <ngx-file-drop
              class="file-drop"
              dropZoneLabel="Drop files here"
              (onFileDrop)="droppedDocument($event)"
              [multiple]="false"
              accept=".png, .jpg, .jpeg"
            >
              <ng-template
                ngx-file-drop-content-tmp
                let-openFileSelector="openFileSelector"
              >
                Arraste a FOTO para cá ou
                <button
                  type="button"
                  mat-flat-button
                  color="primary"
                  class="mat-elevation-z4"
                  id="browseFilesButton"
                  (click)="openFileSelector()"
                >
                  Buscar arquivo
                </button>
              </ng-template>
            </ngx-file-drop>
            <div class="upload-table-container">
              <table class="upload-table">
                <tbody class="upload-name-style">
                  <tr *ngFor="let item of documents; let i = index">
                    <td>
                      <strong>{{ item.relativePath }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </mat-grid-tile>
      </mat-grid-list>

      <!-- Divider -->
      <hr class="solid" />

      <!-- Contact -->
      <mat-card-subtitle>Contatos:</mat-card-subtitle>
      <!-- Colunas -->
      <mat-grid-list cols="2" rowHeight="100px">
        <!-- Coluna 1 -->
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="legacy">
            <mat-label>Telefone</mat-label>
            <input
              [(ngModel)]="person.contact.phone"
              name="phone"
              [formControl]="phoneFormControl"
              matInput
            />
            <mat-icon matSuffix>add_business</mat-icon>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Coluna 2 -->
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="legacy">
            <mat-label>Celular</mat-label>
            <input
              [(ngModel)]="person.contact.cellphone"
              name="cellphone"
              [formControl]="cellphoneFormControl"
              matInput
            />
            <mat-icon matSuffix>domain</mat-icon>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>

      <!-- Divider -->
      <hr class="solid" />

      <!-- Address -->
      <mat-card-subtitle>Endereço:</mat-card-subtitle>
      <!-- Colunas -->
      <mat-grid-list cols="3" rowHeight="100px">
        <!-- Coluna 1 -->
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="legacy">
            <mat-label>CEP</mat-label>
            <input
              [(ngModel)]="person.address.cep"
              name="cep"
              [formControl]="cepFormControl"
              type="text"
              matInput
              mask="00000-000"
            />
            <mat-icon matSuffix>location_on</mat-icon>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Coluna 2 -->
        <mat-grid-tile colspan="1">
          <mat-form-field class="fullWidth" appearance="legacy">
            <mat-label>Estado</mat-label>
            <input
              [(ngModel)]="person.address.uf"
              name="uf"
              [formControl]="ufFormControl"
              type="uf"
              matInput
            />
            <mat-icon matSuffix>satellite</mat-icon>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Coluna 3 -->
        <mat-grid-tile colspan="1">
          <mat-form-field class="fullWidth" appearance="legacy">
            <mat-label>Cidade</mat-label>
            <input
              [(ngModel)]="person.address.city"
              name="city"
              [formControl]="cityFormControl"
              type="city"
              matInput
            />
            <mat-icon matSuffix>location_city</mat-icon>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
      <!-- Colunas -->
      <mat-grid-list cols="3" rowHeight="100px">
        <!-- Coluna 1 -->
        <mat-grid-tile colspan="1">
          <mat-form-field class="fullWidth" appearance="legacy">
            <mat-label>Rua</mat-label>
            <input
              [(ngModel)]="person.address.streetName"
              name="streetName"
              [formControl]="streetNameFormControl"
              type="streetName"
              matInput
            />
            <mat-icon matSuffix>add_road</mat-icon>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Coluna 2 -->
        <mat-grid-tile colspan="1">
          <mat-form-field class="fullWidth" appearance="legacy">
            <mat-label>Bairro</mat-label>
            <input
              [(ngModel)]="person.address.neighborhood"
              name="neighborhood"
              [formControl]="neighborhoodFormControl"
              type="neighborhood"
              matInput
            />
            <mat-icon matSuffix>signpost</mat-icon>
          </mat-form-field>
        </mat-grid-tile>

        <!-- Coluna 3 -->
        <mat-grid-tile colspan="1">
          <mat-form-field class="fullWidth" appearance="legacy">
            <mat-label>Complemento</mat-label>
            <input
              [(ngModel)]="person.address.complement"
              name="complement"
              [formControl]="complementFormControl"
              type="complement"
              matInput
            />
            <mat-icon matSuffix>edit_location_alt</mat-icon>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>

      <!-- Divider -->
      <hr class="solid" />

      <!-- Routines -->
      <mat-card-subtitle>Rotinas:</mat-card-subtitle>
      <mat-grid-list cols="1">
        <div class="container">
          <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Nome</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="responsibility">
                <th mat-header-cell *matHeaderCellDef>Função</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.responsibility.name }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <mat-paginator
              [pageSizeOptions]="[5, 2, 10, 20, 50, 100]"
              showFirstLastButtons
            ></mat-paginator>
          </div>
        </div>
      </mat-grid-list>
    </form>
  </mat-card-content>

  <!-- Actions -->
  <mat-card-actions align="end">
    <button
      *ngIf="!id"
      (click)="save()"
      [disabled]="!validateFields()"
      class="success-button mat-elevation-z4"
      mat-raised-button
      extended
      matTooltip="Salvar as informações preenchidas"
    >
      <mat-icon>check</mat-icon> SALVAR
    </button>
    <button
      *ngIf="id"
      (click)="update()"
      [disabled]="!validateFields()"
      class="success-button mat-elevation-z4"
      mat-raised-button
      extended
      matTooltip="Salvar as informações preenchidas"
    >
      <mat-icon>check</mat-icon> ALTERAR
    </button>
    <button
      *ngIf="id"
      routerLink="../../"
      class="mat-elevation-z4"
      mat-raised-button
      color="warn"
      extended
      matTooltip="Cancelar as informações preenchidas e voltar a página"
    >
      <mat-icon>block</mat-icon> CANCELAR
    </button>
    <button
      *ngIf="!id"
      routerLink="../"
      class="mat-elevation-z4"
      mat-raised-button
      color="warn"
      extended
      matTooltip="Cancelar as informações preenchidas e voltar a página"
    >
      <mat-icon>block</mat-icon> CANCELAR
    </button>
  </mat-card-actions>

  <!-- Footer -->
  <mat-card-footer>
    <mat-progress-bar mode="indeterminate" *ngIf="isSaving"></mat-progress-bar>
  </mat-card-footer>
</mat-card>
