<mat-card class="card mat-elevation-z8">
  <!-- Header -->
  <mat-card-header>
    <mat-card-title>Alterar Permissões</mat-card-title>
    <mat-card-subtitle>Formulário de alteração</mat-card-subtitle>
  </mat-card-header>

  <!-- Content -->
  <mat-card-content>
    <form class="form">
      <!-- Colunas -->
      <mat-grid-list cols="2" rowHeight="100px">
        <!-- Coluna 1 -->
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="outline">
            <mat-label>Selecione um Usuário</mat-label>
            <mat-select
              [(ngModel)]="userResponse"
              name="user"
              [formControl]="user"
              required
            >
              <mat-option *ngFor="let user of users" [value]="user">{{
                user.username
              }}</mat-option>
            </mat-select>
            <mat-hint *ngIf="!user.value" align="end" style="color: red"
              >Campo obrigatório</mat-hint
            >
          </mat-form-field>
        </mat-grid-tile>

        <!-- Coluna 2 -->
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="outline">
            <mat-label>Selecione as Permissões</mat-label>
            <mat-select
              [(ngModel)]="userResponse.roles"
              [compareWith]="compareRoles"
              name="role"
              [formControl]="role"
              required
              multiple
            >
              <mat-option
                *ngFor="let role of roleLabels"
                [value]="role.value"
                >{{ role.label }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </form>
  </mat-card-content>

  <!-- Actions -->
  <mat-card-actions align="end">
    <button
      (click)="savePermitions()"
      [disabled]="!validateFields()"
      class="success-button mat-elevation-z4"
      mat-raised-button
      extended
      matTooltip="Salvar as informações preenchidas"
    >
      <mat-icon>check</mat-icon> SALVAR
    </button>
    <button
      (click)="routeToHome()"
      class="mat-elevation-z4"
      mat-raised-button
      color="warn"
      extended
      matTooltip="Cancelar as informações preenchidas e voltar a página"
    >
      <mat-icon>block</mat-icon> CANCELAR
    </button>
  </mat-card-actions>

  <!-- Footer -->
  <mat-card-footer>
    <mat-progress-bar mode="indeterminate" *ngIf="saving"></mat-progress-bar>
  </mat-card-footer>
</mat-card>
