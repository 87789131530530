<div class="container mt-4">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Funcionários</li>
    </ol>
  </nav>

  <h1>Lista de Funcionários</h1>

  <div class="text-end mb-3">
    <button class="btn btn-primary" (click)="router.navigate(['/employees/create'])">
      Criar Novo Funcionário
    </button>
  </div>

  <div *ngIf="loading" class="alert alert-info">
    Carregando funcionários...
  </div>

  <app-zebra-employee-table *ngIf="!loading"
    [headers]="employeeHeaders"
    [data]="employeeData"
    [itemsPerPage]="5"
    (edit)="onEdit($event)"
    (delete)="onDelete($event)"
    (view)="onView($event)"></app-zebra-employee-table>
</div>
